import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { from, of, throwError } from 'rxjs';
import { catchError, concatMap, exhaustMap, map } from 'rxjs/operators';

import { AuthActions } from './auth.actions';
import { selectUserName } from './auth.selectors';
import { CONSTANTS } from 'src/app/auth/constants';
import { UserDetailsService } from 'src/app/core/api/user-details.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store,
    private userDetailsService: UserDetailsService,
    private permissionsService: NgxPermissionsService
  ) {}

  getUserPhoto$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserDataSuccess),
      concatLatestFrom(() => this.store.select(selectUserName)),
      exhaustMap(([, userName]) =>
        from(
          this.http
            .get(CONSTANTS.USER_PHOTO_URL, { responseType: 'blob' })
            .pipe(
              map((photo) => URL.createObjectURL(photo)),
              catchError(() => {
                return throwError(() => 'Error getting user photo');
              })
            )
        ).pipe(
          map((photo) => {
            return AuthActions.getUserPhotoSuccess({ photo });
          }),
          catchError(() => {
            const photo = `https://ui-avatars.com/api/?name=${userName}`;

            return of(AuthActions.getUserPhotoFailure({ photo }));
          })
        )
      )
    );
  });

  getUserRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserDataSuccess),
      concatMap(() => this.userDetailsService.syncUserInfo()),
      concatMap((httpStatus) => {
        const syncedWithSuccess = httpStatus === 200 || httpStatus === 201;

        if (syncedWithSuccess) {
          return this.userDetailsService.getUserDetails();
        }

        throw new Error('Error getting user details');
      }),
      map((userDetails) => {
        this.permissionsService.loadPermissions(userDetails.roles);

        return AuthActions.getUserInfoSuccess({
          agreed: !!userDetails.preferences?.termsOfUse,
        });
      }),
      catchError(() => of(AuthActions.getUserInfoFailure()))
    );
  });
}
