import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { ChatActions } from 'src/app/store/chat/chat.actions';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  public readonly history: string[] = [];
  hasHistory$: Observable<boolean>;

  constructor(
    private router: Router,
    private location: Location,
    private store: Store
  ) {
    this.hasHistory$ = this.router.events.pipe(
      map((event) => {
        if (event instanceof NavigationEnd) {
          this.history.push(event.urlAfterRedirects);
        }

        return this.history.length > 1;
      })
    );
  }

  back(): void {
    this.history.pop();

    this.returningToChat();

    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  returningToChat() {
    const currentRoute = this.history[this.history.length - 1];
    const returningToChat = currentRoute === '/chat';

    if (returningToChat) {
      const resetStateToShowChatWelcomePage = this.store.dispatch(
        ChatActions.reset()
      );
    }
  }
}
