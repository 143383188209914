import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent {
  localStorageTermsOfUseKey = 'v1.0.0@stellar.hasShownTermsOfUse';
  hasShownTermsOfUse =
    localStorage.getItem(this.localStorageTermsOfUseKey) === 'true';
  shouldNotShowTermsOfUse = true;

  constructor(private router: Router) {
    this.checkRoute();
  }

  checkRoute() {
    const currentRoute = this.router.url;
    const exceptionRoutes = ['/terms-of-use', '/privacy-notice'];
    const shouldHideTerms = exceptionRoutes.includes(currentRoute);

    if (shouldHideTerms) {
      this.shouldNotShowTermsOfUse = true;
    } else {
      this.shouldNotShowTermsOfUse = false;
    }
  }

  onAcceptClick() {
    this.dontShowTermsAnymore();
  }

  dontShowTermsAnymore() {
    localStorage.setItem(this.localStorageTermsOfUseKey, 'true');
    this.hasShownTermsOfUse = true;
  }
}
