import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  Connector,
  FormatedContexts,
  FormatedLandingZone,
  Product,
  RagDocument,
  UploadedBenchmarkDocuments,
} from 'src/app/core/api/rag.service';

export const RagActions = createActionGroup({
  source: 'Rag',
  events: {
    getLandingZonesRequest: emptyProps(),

    getLandingZonesSuccess: props<{ landing_zones: FormatedLandingZone[] }>(),
    getProductsSuccess: props<{ products: Product[] }>(),
    getConnectorsSuccess: props<{ connectors: Connector[] }>(),
    getContextsSuccess: props<{ contexts: FormatedContexts[] }>(),

    setSelectedLandingZone: props<{ landing_zone: FormatedLandingZone }>(),
    setSelectedProduct: props<{ product: Product }>(),
    setSelectedConnector: props<{ connector: Connector }>(),
    setSelectedContext: props<{ context: FormatedContexts }>(),

    getUploadTableDataRequest: emptyProps(),
    getUploadTableDataSuccess: props<{ uploadTableData: UploadTableItem[] }>(),
    removeUploadTableItem: props<{ id: string }>(),

    getBenchmarkTableDataRequest: emptyProps(),
    getBenchmarkTableDataSuccess: props<{
      benchmarkTableData: BenchmarkTableItem[];
    }>(),
    removeBenchmarkTableItem: props<{ id: string }>(),

    setPdfDocuments: props<{ pdfDocuments: RagDocument[] }>(),
    setTotalQuestions: props<{
      totalQuestions: number;
      totalSynthQuestions: number;
    }>(),
    setBenchmarkDocuments: props<{
      benchmarkDocuments: UploadedBenchmarkDocuments[];
    }>(),
    reset: emptyProps(),
  },
});
