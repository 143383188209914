import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { UserDetailsService } from 'src/app/core/api/user-details.service';
import { ModalService } from 'src/app/modal/services/modal.service';
import { AuthActions } from 'src/app/store/auth/auth.actions';
import { selectUserName } from 'src/app/store/auth/auth.selectors';

@Component({
  selector: 'app-agree',
  templateUrl: './agree.component.html',
  styleUrls: ['./agree.component.scss'],
})
export class AgreeComponent {
  constructor(
    protected modalService: ModalService,
    private store: Store,
    private userDetailService: UserDetailsService
  ) {}

  userName$ = this.store.select(selectUserName);
  agreeChecked = false;

  termsModalId = 'terms-of-use';
  privacyModalId = 'privacy-notice';

  openTermsModal() {
    this.modalService.open(this.termsModalId);
  }

  openPrivacyModal() {
    this.modalService.open(this.privacyModalId);
  }

  onAgreeSubmit() {
    this.userDetailService
      .setUserAgreement(this.agreeChecked)
      .pipe(
        tap((res) => {
          this.store.dispatch(
            AuthActions.setUserAgreed({ agreed: res.termsOfUse })
          );
        })
      )
      .subscribe();
  }
}
