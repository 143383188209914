import { NgModule } from '@angular/core';
import { SidebarComponent } from './sidebar.component';
import { HistoryComponent } from './history/history.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'src/app/directives/tooltip/tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'src/app/modal/modal.module';
import { MaterialModule } from 'src/app/core/material/material.module';
import { DynamicButtonModule } from '../dynamic-button/dynamic-button.module';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [SidebarComponent, HistoryComponent],
  imports: [
    CommonModule,
    RouterModule,
    TooltipModule,
    TranslateModule,
    ModalModule,
    MaterialModule,
    DynamicButtonModule,
    NgxPermissionsModule.forChild(),
  ],
  exports: [SidebarComponent],
})
export class SidebarModule {}
