@if (!shouldNotShowTermsOfUse && !hasShownTermsOfUse) {
  <div class="modal">
    <div class="modal-content">
      <div [innerHTML]="'COMPONENTS.TERMS_OF_USE.TERMS' | translate"></div>
      <button (click)="onAcceptClick()">
        {{ 'COMPONENTS.TERMS_OF_USE.ACCEPT' | translate }}
      </button>
    </div>
  </div>
}
