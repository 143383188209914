import { NgModule } from '@angular/core';
import { ModalComponent } from './modal.component';
import { CommonModule } from '@angular/common';
import { FeedbackComponent } from './variations/feedback/feedback.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicButtonModule } from '../components/dynamic-button/dynamic-button.module';
import { DeleteHistoryComponent } from './variations/confirmation/delete-history-confirm.component';
import { DeleteItemComponent } from './variations/deleteItem/delete-item-confirm.component';
import { GroundTruthComponent } from './variations/ground-truth/ground-truth.component';
import { TableModule } from 'primeng/table';
import { MaterialModule } from '../core/material/material.module';
import { SuccessModalComponent } from './variations/success-modal/success-modal.component';
import { ConfirmDialogComponent } from './variations/confirm-dialog/confirm-dialog.component';
import { QuestionFormComponent } from './variations/question-form/question-form.component';
import { TermsModalComponent } from './variations/terms-of-use/terms-of-use.component';
import { PrivacyModalComponent } from './variations/privacy-notice/privacy-notice.component';

@NgModule({
  declarations: [
    ModalComponent,
    FeedbackComponent,
    DeleteHistoryComponent,
    DeleteItemComponent,
    GroundTruthComponent,
    SuccessModalComponent,
    ConfirmDialogComponent,
    QuestionFormComponent,
    TermsModalComponent,
    PrivacyModalComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    DynamicButtonModule,
    TableModule,
    MaterialModule,
  ],
  exports: [
    ModalComponent,
    FeedbackComponent,
    DeleteHistoryComponent,
    DeleteItemComponent,
    GroundTruthComponent,
    SuccessModalComponent,
    ConfirmDialogComponent,
    QuestionFormComponent,
    TermsModalComponent,
    PrivacyModalComponent,
  ],
})
export class ModalModule {}
