<div class="overlay">
  <div class="container">
    <div class="image">
      <img src="assets/images/stellar-login.svg" alt="Stellar" />
    </div>
    <div class="wellcome">Welcome to the Stellar GenAI</div>

    <div class="sso-login">
      <button class="btn-sso-login" (click)="logIn()">SSO Login</button>
    </div>
    <div class="sso-problemn">
      <a class="sso-login">Problem with SSO?</a>
    </div>
    <div class="abi-img">
      <img src="assets/images/logo-expanded-transparent.png" alt="ABI Logo" />
    </div>
  </div>
</div>
