import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import {
  ApplicationInsights,
  IPageViewTelemetry,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoggingService {
  private _appInsights!: ApplicationInsights;
  private _angularPlugin: AngularPlugin = new AngularPlugin();
  private _currentUser!: AccountInfo;

  constructor(
    private _router: Router,
    private _authService: MsalService
  ) {
    this.init();
    this._currentUser =
      this._authService.instance.getAllAccounts()[0] as AccountInfo;
  }

  init() {
    if (environment.APP_INSIGHTS.CONNECTION_STRING) {
      this._appInsights = new ApplicationInsights({
        config: {
          appId: environment.APP_REGISTRATION.CLIENT_ID,
          extensions: [this._angularPlugin],
          extensionConfig: {
            [this._angularPlugin.identifier]: { router: this._router },
          },
          instrumentationKey: environment.APP_INSIGHTS.INSTRUMENTATION_KEY,
          connectionString: environment.APP_INSIGHTS.CONNECTION_STRING,
          enableAutoRouteTracking: true,
          enableCorsCorrelation: true,
          enableRequestHeaderTracking: true,
          enableResponseHeaderTracking: true,
          loggingLevelTelemetry: 1,
          enableAjaxErrorStatusText: true,
        },
      });

      this._appInsights.loadAppInsights();
      /**
       *  ----- >>> WARNING <<< -----
       * Not remove this._appInsights.emptyQueue() line.
       *
       * Because this remove all tags with randomized generated values that appear in application insights metrics.
       *
       * @reference https://github.com/Microsoft/ApplicationInsights-JS/issues/571
       **/
      this._appInsights.emptyQueue();

      this.addTelemetryInitializer();
      this.logActiveComponent();
    }
  }

  addTelemetryInitializer() {
    this._appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      const currentUserEmail = this._currentUser.username || '';

      envelope.tags = envelope.tags || [];

      this._appInsights.clearAuthenticatedUserContext();
      this._appInsights.setAuthenticatedUserContext(
        this._currentUser.localAccountId,
        this._currentUser.tenantId
      );

      this._appInsights.context.user.id = currentUserEmail;
      this._appInsights.context.user.authenticatedId =
        this._currentUser.localAccountId;
      this._appInsights.context.user.accountId = this._currentUser.tenantId;

      if (envelope.tags) {
        envelope.tags['ai.userId'] = currentUserEmail;
        envelope.tags['ai.user.id'] = currentUserEmail;
        envelope.tags['ai.user.authUserId'] = this._currentUser.localAccountId;
        envelope.tags['ai.authenticatedId'] = this._currentUser.localAccountId;
        envelope.tags['ai.accountId'] = this._currentUser.tenantId;
      }
    });
  }

  private logActiveComponent() {
    this._router.events
      .pipe(
        filter(
          (event: unknown): event is ResolveEnd => event instanceof ResolveEnd
        )
      )
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent)
          this.appInsights.trackPageView({
            name: activatedComponent.name,
            uri: event.urlAfterRedirects,
          });
      });
  }

  private getActivatedComponent(
    snapshot: ActivatedRouteSnapshot
  ): IPageViewTelemetry | null {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }

  logPageView(name?: string, url?: string) {
    this._appInsights.trackPageView({ name: name, uri: url });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this._appInsights.trackEvent(
      { name: name },
      this.getDefaultProperties(properties)
    );
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    this._appInsights.trackMetric(
      { name: name, average: average },
      this.getDefaultProperties(properties)
    );
  }

  logException(exception: Error, severityLevel?: number) {
    this._appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this._appInsights.trackTrace(
      { message: message },
      this.getDefaultProperties(properties)
    );
  }

  private getDefaultProperties(properties?: { [key: string]: any }) {
    const defaultProperties = {
      'ai.user.id': this._currentUser.username,
      'ai.user.authUserId': this._currentUser.localAccountId,
      'ai.authenticatedId': this._currentUser.localAccountId,
      'ai.accountId': this._currentUser.tenantId,
    };

    return { ...defaultProperties, ...properties };
  }

  get appInsights() {
    return this._appInsights;
  }
}
