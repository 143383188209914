import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { ngxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
    pathMatch: 'full',
    data: {
      showLayout: true,
    },
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('./pages/chat/chat.module').then((m) => m.ChatModule),
    canActivate: [MsalGuard],
    data: {
      showLayout: true,
    },
  },
  {
    path: 'authorization',
    loadChildren: () =>
      import('./pages/authorization/authorization.module').then(
        (m) => m.AuthorizationModule
      ),
    data: {
      showLayout: true,
    },
  },
  {
    path: 'rag',
    loadChildren: () =>
      import('./pages/rag/rag.module').then((m) => m.RagModule),
    canActivate: [ngxPermissionsGuard],
    data: {
      permissions: {
        only: 'Rag:Creator',
        redirectTo: 'home',
      },
      showLayout: true,
    },
  },
  {
    path: 'terms-of-use',
    loadChildren: () =>
      import('./pages/terms-of-use/terms-of-use-page.module').then(
        (m) => m.TermsOfUsePageModule
      ),
    data: {
      showLayout: true,
    },
  },
  {
    path: 'privacy-notice',
    loadChildren: () =>
      import('./pages/privacy-notice/privacy-notice.module').then(
        (m) => m.PrivacyNoticePageModule
      ),
    data: {
      showLayout: true,
    },
  },
  {
    path: 'cookie-policy',
    loadChildren: () =>
      import('./pages/cookie-policy/cookie-policy.module').then(
        (m) => m.CookiePolicyModule
      ),
    data: {
      showLayout: true,
    },
  },
  {
    path: 'legal-information',
    loadChildren: () =>
      import('./pages/legal-information/legal-information.module').then(
        (m) => m.LegalInformationModule
      ),
    data: {
      showLayout: true,
    },
  },
  {
    path: 'session-expired',
    loadChildren: () =>
      import('./pages/session-expired/session-expired.module').then(
        (m) => m.SessionExpiredModule
      ),
    data: {
      showLayout: false,
    },
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('./pages/maintenance/maintenance.module').then(
        (m) => m.MaintenanceModule
      ),
    data: {
      showLayout: false,
    },
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
