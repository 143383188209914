import { Component } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/' };
  showBreadcrumb = false;
  breadcrumbItems = this.translate.stream('COMPONENTS.HEADER.BREADCRUMB');

  constructor(
    private router: Router,
    private translate: TranslateService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const onlyShowBreadcrumOnRagPages = event.url.includes('/rag');

        this.showBreadcrumb = onlyShowBreadcrumOnRagPages;
      }
    });
  }
}
