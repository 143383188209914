<div class="page-container">
  <div class="modal">
    <div class="modal-content">
      <h1>You're new here!</h1>
      <h2>
        Since it's your first time here, please fill in some info for a more
        personalized experience.
      </h2>
      <p>Name</p>
      <input [disabled]="true" [value]="userName$ | async" />
      <p>
        <p-checkbox
          [(ngModel)]="agreeChecked"
          [binary]="true"
          inputId="binary"
        />
        I accept the
        <button class="ButtonModal" (click)="openTermsModal()">T&C</button>
        I acknowledge the processing of my personal data in accordance with the
        <button class="ButtonModal" (click)="openPrivacyModal()">
          Privacy Notice</button
        >.
      </p>
      <dynamic-button
        [variant]="'agree'"
        [disabled]="!agreeChecked"
        (click)="onAgreeSubmit()"
        >Sign Up</dynamic-button
      >
    </div>
    <div class="logo-image">
      <img src="assets/images/logo-expanded-transparent.png" alt="ABI Logo" />
    </div>
  </div>
</div>

<modal [id]="termsModalId">
  <terms-modal />
</modal>

<modal [id]="privacyModalId">
  <privacy-modal />
</modal>
