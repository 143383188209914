<article class="container">
  <div class="header">
    <button (click)="close()">X</button>
    <h1>PRIVACY NOTICE – Stellar/GPTap</h1>
  </div>
  <div class="body">
    <p>
      Welcome to Stellar (the “Tool”), AB InBev’s internal generative AI
      Assistant. The Tool is a private instance of Chat GPT created exclusively
      for AB InBev, with the purpose of assisting you in your work-related tasks
      and supporting on provide information/self-service related to ABI internal
      instructions and routines processes.
    </p>

    <ol>
      <li>
        <b>INTRODUCTION AND DATA CONTROLLER IDENTIFICATION</b>
        <p>
          This Privacy Notice for Stellar and GPTap (the “Tool”), explains how
          ANHEUSER-BUSCH INBEV SA/NV, a company organized and existing under the
          laws of Belgium, whose registered office is at Grote Market 1, 1000
          Brussels, Belgium, registered with the Register of Legal Entities
          under company number 417.497.106 and its
          <a
            href="https://anheuserbuschinbev.sharepoint.com/:x:/r/sites/Europe-DataPrivacy/Shared%20Documents/General/1.%20Internal/1.%20Power%20BI%20Reports/Entities.xlsx?d=w69032e6119b14943a8bf35993d862dc1&csf=1&web=1&e=2Z8xGD"
            >affiliates</a
          >
          (collectively, “we”, “us”, “our”, “AB InBev”, “company” or “ABI”) will
          process employee (hereinafter “you” or “your”) personal data as joint
          data controllers when you use the Tool.  
        </p>
        <p>
          Any collection and processing of personal data, including employee
          data, by this Tool will be done in accordance with the laws,
          regulations, policies, and contractual obligations for the protection
          of employee rights and privacy. You may consult our local policies
          (available in this link:
          <a
            href="https://anheuserbuschinbev.sharepoint.com/sites/ABIGlobalPolicies"
            >https://anheuserbuschinbev.sharepoint.com/sites/ABIGlobalPolicies</a
          >
          ) and seek additional information from local legal/E&C teams.
        </p>
      </li>

      <li>
        <b>
          HOW THE TOOL FUNCTIONS AND WHAT PERSONAL DATA WILL BE COLLECTED, USED
          AND OTHERWISE PROCESSED
        </b>
        <p>
          The Tool is built within our own Microsoft Azure Cloud Infrastructure
          and it enables generative AI capabilities from Open AI within our
          ecosystem. When you present a query to the system, it will search on
          (a) a static set of data trained with internal information to
          facilitate instructions related to ABI internal processes (as
          Knowledge articles and process documentation from areas such as
          People, IT and Procurement), (b) public Internet sources and a static
          set of data captured from the Internet as of the last training date of
          the Tool for answers that are most responsive to the query. Please
          note that, when the tool provides information from external sources,
          it will provide transparency and clear disclaimer on that.
        </p>
        <p>
          Considering the Tool is built-in to ABI’s cloud infrastructure, and
          considering our internal privacy and security features, detailed
          below, the following personal data will be collected:
        </p>
        <ul>
          <li>
            <b>Your Single Sign-On,</b> as per our internal parameters, to
            enable your login in the platform and to monitor its use. Your
            password will not be recorded by the Tool;
          </li>
          <li>
            <b>Your name, e-mail, country and zone,</b> to monitor both platform
            usage and that you are using it according to these Terms of Use,
            ABI’s internal policies and applicable laws and regulations;
          </li>
          <li>
            <b>Your location,</b>to generate content/responses relevant to users
            of that specific location/office/zone;
          </li>
          <li>
            <b
              >Your queries to Stellar or GPTap [and the corresponding outputs
              that are generated],</b
            >
            to monitor your use of the Tool is done according to these Terms of
            Use, ABI’s internal policies and applicable laws and regulations and
            to improve the accuracy and quality of the Tool’s responses to
            queries;
          </li>
          <li>
            <b
              >Your feedback to the corresponding outputs that are generated,</b
            >
            to improve the functionality of the Tool;
          </li>
          <li>
            <b
              >Your browsing activities, user preferences, analytics data and
              device information,</b
            >to understand how you interact with our site and improve our
            services according to our Cookie Notice.
          </li>
        </ul>
        <p>
          Microsoft will not access the queries you provide and the
          corresponding outputs that are generated, except for cases of express
          and flagrant misuse (for example, that may breach basic human rights).
          Please refer to
          <a
            href="https://protect-eu.mimecast.com/s/y_bXCJQxDHxLANPLsGSZLa?domain=microsoft.com"
            >Terms of Microsoft Generative AI Services</a
          >
          and
          <a
            href="https://protect-eu.mimecast.com/s/23rMCG8rnswZOvoZTKKlp5?domain=microsoft.com"
            >Azure OpenAI Service Use Limitation</a
          >
          for more information. If you have any questions or concerns about
          this, please consult your Legal local counsel.
        </p>
      </li>

      <li>
        <b>OUR LEGAL BASIS FOR PROCESSING</b>
        <p>
          If you choose to use the Tool, personal data will be collected and
          processed based on:
        </p>
        <ul>
          <li>
            the contractual necessity/performance of contract to allow you to
            use the Tool in the context of your employment at ABI;
          </li>
          <li>
            the legitimate interests of ABI to monitor, and improve the Tool,
            all in accordance with our privacy and security policies and
            applicable laws and regulations;
          </li>
          <li>
            the legitimate interests of ABI to use strictly necessary cookies
            (i.e. for communications to take place over a network and/or to
            provide a service explicitly requested by you);
          </li>
          <li>
            your prior consent for ABI to use not strictly necessary cookies.
          </li>
        </ul>
      </li>

      <li>
        <b>RECIPIENTS AND INTERNATIONAL TRANSFERS </b>

        <p>
          Your personal data may be processed by ABI Affiliates to enable,
          monitor and improve the Tool.
        </p>
        <p>
          The personal data will also be processed by our service providers
          acting on our behalf to deploy and support the Platform.
        </p>
        <p>
          Some of these recipients may be in territories outside the country
          where your personal data has been collected, including countries such
          as the United States of America which may not offer a level of data
          protection comparable to that of the country where your data has been
          collected.
        </p>
        <p>
          In principle, we only transfer personal data to countries deemed
          adequate in data protection law. In case we transfer your personal
          data to non-adequate countries, we will ensure appropriate safeguards
          in place, as applicable, including standard contractual clauses or
          binding corporate rules. In such case, we will also conduct any
          necessary assessments (TIA’s) and implement supplementary measures to
          ensure an appropriate level of data protection.
        </p>
        <p>
          For more information on the international transfers that take place in
          the context of the Tool and/or to obtain a copy of the applicable
          safeguards in place, please contact us by using the contact details
          set out in section 5 below.
        </p>
      </li>

      <li>
        <b>DATA RETENTION AND SECURITY </b>
        <p>
          We do not retain your personal data longer than strictly necessary for
          the purposes for which they were collected:
        </p>
        <ul>
          <li>
            By default, your queries are retained for 120 days but you can
            always delete them in the Tool.
          </li>
          <li>
            Any other personal data will be retained no longer than strictly
            necessary and will be deleted or anonymized when they are no longer
            necessary.
          </li>
        </ul>
        <p>
          From a security perspective, this Tool has undergone our internal IT
          Compliance & Security protocols and is considered secure to be used
          internally.
        </p>
      </li>

      <li>
        <b>YOUR RIGHTS AS A DATA SUBJECT </b>
        <p>
          Where applicable and subject to the conditions set out under
          applicable data protection laws, you may have the following rights
          concerning the processing of your personal data:  
        </p>
        <ul>
          <li>
            the right to request confirmation and information on the data
            processing and a copy of the personal data we are processing about
            you;  
          </li>
          <li>
            the right to demand that incorrect data are being corrected and data
            which are inappropriate or no longer needed are being deleted;  
          </li>
          <li>
            the right to request from us to restrict the processing of your data
            as well as the right to data portability; 
          </li>
          <li>
            the right to object to the processing of your personal data on
            grounds relating to your particular situation;
          </li>
          <li>
            the right to withdraw your consent, in case it applies, without
            affecting the lawfulness of the processing based on consent before
            its withdrawal.
          </li>
          <li>
            the right to lodge a complaint with a supervisory authority if you
            consider that the processing of the personal data is not compliant
            with the applicable data protection law.
          </li>
        </ul>
        <p>
          You can exercise your rights using the contact details set out in
          section 7 below.
        </p>
      </li>

      <li>
        <b>CONTACT & GRIEVANCE MECHANISM</b>
        <p>
          For any query about the Tool, its misuse or your personal data, you
          may contact us using our
          <a href="https://contactus.ab-inbev.com/en"
            >global “contact us” website</a
          >
          (for personal data concerns, please select the typology “Contact Data
          Protection Officer” in the Contact Us Form) or your ABI contact
          person. 
        </p>
        <p>
          If you are in the European Economic Area, you can also contact our
          data protection officer at
          <a href="mailto:dataprotectionofficer_eu@ab-inbev.com"
            >dataprotectionofficer_eu&#64;ab-inbev.com</a
          >. If you are in Brazil, you can contact the Brazilian Data Protection
          Officer at
          <a href="mailto:dataprivacy@ambev.com.br"
            >dataprivacy&#64;ambev.com.br</a
          >. 
        </p>
        <p>
          ABI holds very high data protection standards, and we therefore
          encourage you to always contact us directly first. If this, however,
          would not suffice to address your concerns, you have the right to
          lodge a complaint with the competent supervisory authority.
        </p>
      </li>

      <li>
        <b>UPDATES </b>

        <p>
          Except to the extent limited by applicable law, from time-to-time ABI
          may decide to change, modify, and/or add all or parts of Privacy
          Notice in the future. Any change to this Privacy Notice will be posted
          on this page and, where required by law and to the extent that we have
          your e-mail address, notified to you by e-mail. We will indicate at
          the top of this Privacy Notice the date on which the Notice was last
          modified.
        </p>
        <p>
          Where required by law, we may also ask you to expressly confirm your
          consent to the modified Privacy Notice.
        </p>
      </li>
    </ol>

    <p>Last updated: June 19th, 2024.</p>
  </div>
  <div class="footer">
    <img src="assets/images/logo-expanded-transparent.png" alt="ABI Logo" />
  </div>
</article>
