import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionExpiredService {
  public session_expired$ = new BehaviorSubject<boolean>(false);

  constructor(
    private ngZone: NgZone,
    private router: Router
  ) {
    this.lastLogin(Date.now());
    this.initInterval();
  }

  private getLastLogin(): string {
    return localStorage.getItem('lastLogin') || '0';
  }

  private lastLogin(value: number) {
    localStorage.setItem('lastLogin', JSON.stringify(value));
  }

  private initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, 1000);
    });
  }

  public reset() {
    this.session_expired$.next(false);
    this.lastLogin(Date.now());
  }

  public expire() {
    this.session_expired$.next(true);
    this.router.navigateByUrl('session-expired');
  }

  private check() {
    const oneHour = 60 * 60 * 1000; // Time for msal access token to expire
    const now = Date.now();
    const timeLeft = parseInt(this.getLastLogin()) + oneHour;
    const diff = timeLeft - now;
    const isTimeout = diff < 0;

    this.ngZone.run(() => {
      if (isTimeout) {
        localStorage.removeItem('user_id');
        localStorage.removeItem('lastLogin');

        this.router.navigateByUrl('session-expired');
        this.session_expired$.next(true);
      }
    });
  }
}
