<div class="container">
  <a
    [routerLink]="['/home']"
    class="option"
    [ngClass]="{ selected: currentRoute === '/home' }"
  >
    <i class="material-icons">home</i>
    {{ 'COMPONENTS.FOOTER_MOBILE.HOME' | translate }}
  </a>
  <a
    [routerLink]="['/chat']"
    class="option"
    (click)="handleNewChat()"
    [ngClass]="{ selected: currentRoute === '/chat' }"
  >
    <i class="material-icons">add_circle_outline</i>
    {{ 'COMPONENTS.FOOTER_MOBILE.NEW_CHAT' | translate }}
  </a>
</div>
