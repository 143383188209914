import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

import { Store } from '@ngrx/store';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import {
  selectShouldShowUserAgreement,
  selectUserPhoto,
  selectUserSync,
} from './store/auth/auth.selectors';
import { AuthenticationResult } from '@azure/msal-browser';
import { AuthActions } from './store/auth/auth.actions';
import { SessionExpiredService } from './core/services/session-expired.service';
import { CONSTANTS } from './auth/constants';
import {
  combineLatest,
  filter,
  iif,
  map,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  background = 'home';

  shouldShowAgreePage$ = this.store.select(selectShouldShowUserAgreement);
  showLayout$ = this.router.events.pipe(
    filter((event) => event instanceof RoutesRecognized),
    map((event) => {
      return (event as RoutesRecognized).state.root.firstChild?.data[
        'showLayout'
      ];
    }),
    shareReplay(1)
  );
  userImage$ = this.store.select(selectUserPhoto);
  userSynced$ = this.store.select(selectUserSync);
  isSessionExpired$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((event) => (event as NavigationEnd).url === '/session-expired')
  );
  dataLoaded$ = combineLatest([
    this.userImage$,
    this.userSynced$,
    this.isSessionExpired$,
  ]).pipe(
    map(
      ([hasImg, hasSynced, isSessionExpired]) =>
        (hasImg && hasSynced) || isSessionExpired
    )
  );

  get authenticated(): boolean {
    return !!this.authService.instance.getActiveAccount();
  }

  constructor(
    private authService: MsalService,
    private store: Store,
    private router: Router,
    private sessionService: SessionExpiredService
  ) {}

  ngOnInit() {
    this.showLayout$.subscribe();
    this.changeBackgroundImageByRoute();

    this.authService
      .handleRedirectObservable()
      .pipe(
        switchMap((res: AuthenticationResult | null) => {
          if (res) this.authService.instance.setActiveAccount(res.account);

          return iif(
            () => !!res,
            of(res as AuthenticationResult),
            this.authService.acquireTokenSilent({
              scopes: CONSTANTS.AUTH_SCOPES,
            })
          );
        }),
        tap({
          next: (res: AuthenticationResult) => {
            this.dispatchUserDataSuccess(res);
          },
          error: (err: any) => {
            console.log('Error on acquire token', err);
            this.sessionService.expire();
          },
        })
      )
      .subscribe();
  }

  private dispatchUserDataSuccess(userData: AuthenticationResult) {
    const {
      accessToken,
      account: { name, username: email, localAccountId: id },
    } = userData;

    this.sessionService.reset();

    const pattern = /(\w+),\s+(\w+)/;
    const groupNames = (name || '').match(pattern);
    const userName = groupNames ? `${groupNames[2]} ${groupNames[1]}` : name;

    this.store.dispatch(
      AuthActions.getUserDataSuccess({
        accessToken,
        email,
        id,
        name: userName as string,
      })
    );
  }

  private changeBackgroundImageByRoute() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        switch (true) {
          case event.url === '/home':
            this.background = 'home';
            break;

          case event.url.includes('/chat'):
            this.background = 'chat';
            break;

          case event.url.includes('/rag'):
            this.background = 'rag';
            break;

          case event.url === '/terms-of-use':
            this.background = 'terms-of-use';
            break;

          case event.url === '/privacy-notice':
            this.background = 'privacy-notice';
            break;

          default:
            break;
        }
      }
    });
  }
}
