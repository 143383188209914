import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const cloneReq = req.clone({
      headers: req.headers.set('applicationId', environment.APPLICATION_ID),
    });

    return next.handle(cloneReq);
  }
}
