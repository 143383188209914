import { createReducer, on } from '@ngrx/store';
import { IAuthReducer } from './auth.store';
import { AuthActions } from './auth.actions';

const initialState: IAuthReducer = {
  authenticated: false,
  synced: false,
  user: {
    accessToken: '',
    email: '',
    id: '',
    name: '',
    photo: '',
    agreed: false,
  },
};

export const authReducer = createReducer<IAuthReducer>(
  initialState,
  on(
    AuthActions.getUserDataSuccess,
    (state, { accessToken, email, id, name }): IAuthReducer => ({
      ...state,
      authenticated: true,
      synced: false,
      user: {
        ...state.user,
        accessToken,
        email,
        id,
        name,
      },
    })
  ),
  on(
    AuthActions.getUserPhotoSuccess,
    (state, { photo }): IAuthReducer => ({
      ...state,
      user: { ...state.user, photo },
    })
  ),
  on(
    AuthActions.getUserPhotoFailure,
    (state, { photo }): IAuthReducer => ({
      ...state,
      user: { ...state.user, photo },
    })
  ),
  on(
    AuthActions.getUserInfoSuccess,
    (state, { agreed }): IAuthReducer => ({
      ...state,
      synced: true,
      user: {
        ...state.user,
        agreed,
      },
    })
  ),
  on(
    AuthActions.setUserAgreed,
    (state, { agreed }): IAuthReducer => ({
      ...state,
      user: {
        ...state.user,
        agreed,
      },
    })
  )
);
