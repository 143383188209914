import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import {
  selectUserEmail,
  selectUserName,
  selectUserPhoto,
} from 'src/app/store/auth/auth.selectors';
import v from 'src/assets/version.json';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent {
  constructor(
    private authService: MsalService,
    private store: Store
  ) {}

  version = v;

  @ViewChild('container') container?: ElementRef;
  isModalOpen = false;

  userImageUrl$ = this.store.select(selectUserPhoto);
  userName$ = this.store.select(selectUserName);
  email$ = this.store.select(selectUserEmail);

  toggleModal() {
    this.isModalOpen = !this.isModalOpen;
  }

  logout() {
    this.authService.logoutRedirect();
  }

  @HostListener('document:mousedown', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const clickedOutside = !this.container?.nativeElement.contains(
      event.target
    );

    if (clickedOutside) {
      this.isModalOpen = false;
    }
  }
}
