import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';

export interface IUserDetails {
  id: string;
  name: string;
  email: string;
  country: string | null;
  officeLocation: string;
  preferences: null | { termsOfUse: boolean };
  roles: string[];
}

interface IUserPreferences {
  userId: string;
  termsOfUse: boolean;
  acceptedCookies: boolean;
  version: number;
  timeStamp: string;
  aggregateId: string;
}

export interface ISyncStatuses {
  status: number;
}
@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  constructor(
    private http: HttpClient,
    private readonly store: Store
  ) {}

  private url = `${environment.GATEWAY_URL}/api/identity`;

  getUserDetails(): Observable<IUserDetails> {
    return this.http.get<IUserDetails>(`${this.url}/me`);
  }

  setUserAgreement(agreed: boolean): Observable<IUserPreferences> {
    return this.http.post<IUserPreferences>(`${this.url}/users/preferences`, {
      termsOfUse: agreed,
    });
  }

  syncUserInfo(): Observable<number> {
    return this.http
      .post<ISyncStatuses>(
        `${this.url}/sync`,
        {},
        {
          observe: 'response',
        }
      )
      .pipe(
        map((res) => {
          return res.status;
        })
      );
  }
}
