import { Component } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'terms-modal',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsModalComponent {
  constructor(private modalService: ModalService) {}

  close() {
    this.modalService.close();
  }
}
