import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ChatActions } from 'src/app/store/chat/chat.actions';

@Component({
  selector: 'footer-mobile',
  templateUrl: './footer-mobile.component.html',
  styleUrls: ['./footer-mobile.component.scss'],
  standalone: true,
  imports: [RouterModule, CommonModule, TranslateModule],
})
export class FooterMobileComponent {
  currentRoute: PossibleRoutes = '/home';

  constructor(
    private router: Router,
    private store: Store
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url as PossibleRoutes;
      }
    });
  }

  handleNewChat() {
    this.store.dispatch(ChatActions.reset());
  }
}
