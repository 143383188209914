<div class="user-icon-div h-100 noselect" #container>
  <button class="btn h-100" (click)="toggleModal()" aria-label="Logout">
    <img
      [src]="userImageUrl$ | async"
      alt=""
      class="circle"
      height="25"
      width="25"
    />
  </button>

  @if (isModalOpen) {
    <div class="modal-container">
      <div class="modal-header">
        <span class="small">Version {{ version }}</span>
        <button class="btn sign-out" (click)="logout()">
          {{ 'COMPONENTS.HEADER.SIGN_OUT' | translate }}
        </button>
      </div>
      <div class="modal-user-info">
        <img
          [src]="userImageUrl$ | async"
          alt=""
          class="circle user-img"
          width="64"
          height="64"
        />
        <div class="user-info">
          <span>
            <b>{{ userName$ | async }}</b>
          </span>
          <span class="small">{{ email$ | async }}</span>
        </div>
      </div>
    </div>
  }
</div>
