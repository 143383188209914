import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Widget } from './socket.service';

export interface IHistory {
  id: string;
  title: string;
  createdAt: string;
}

interface IFeedback {
  liked: boolean;
}

export interface IConversationHistory {
  id: string;
  question: string;
  answer: string;
  context: string;
  connectorId: string;
  totalTokens?: number;
  feedbacks: IFeedback[];
  widgets: Widget[] | null;
  actionId: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class ChatHistoryService {
  constructor(private http: HttpClient) {}

  private url = `${environment.BACKEND_URL}/api/conversations`;

  getHistory(): Observable<IHistory[]> {
    return this.http.get<IHistory[]>(this.url);
  }

  getConversationById(
    conversationId: string
  ): Observable<IConversationHistory[]> {
    return this.http.get<IConversationHistory[]>(
      `${this.url}/${conversationId}/messages`
    );
  }

  deleteConversationById(conversationId: string): Observable<any> {
    return this.http.delete(`${this.url}/${conversationId}`);
  }

  deleteAllHistory(): Observable<any> {
    return this.http.delete(`${this.url}`);
  }
}
