import { Injectable } from '@angular/core';

interface IUserPreferences {
  theme: 'dark' | 'light';
  language: 'en' | 'pt' | 'es';
}

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  private readonly storage_key = 'user-pref';
  private storage: Storage;
  private defaultConfig: IUserPreferences = {
    language: 'en',
    theme: 'light',
  };

  constructor() {
    this.storage = window.localStorage;
  }

  private getStorage() {
    const storageAsString = this.storage.getItem(this.storage_key);

    if (storageAsString) {
      const storageAsJSON: IUserPreferences = JSON.parse(storageAsString);

      return storageAsJSON;
    }

    return null;
  }

  private setStorage(userPreferences: IUserPreferences) {
    this.storage.setItem(this.storage_key, JSON.stringify(userPreferences));
  }

  getAll(): IUserPreferences | null {
    const storage = this.getStorage();

    if (storage) {
      return storage;
    }

    return null;
  }

  set<K extends keyof IUserPreferences>(key: K, value: IUserPreferences[K]) {
    const storage = this.getStorage();

    if (storage) {
      storage[key] = value;

      this.setStorage(storage);
    } else {
      this.defaultConfig[key] = value;

      this.setStorage(this.defaultConfig);
    }
  }
}
