import { createReducer, on } from '@ngrx/store';
import { SidebarActions } from './sidebar.actions';
import { ISidebarReducer } from './sidebar.state';

export const initialState: ISidebarReducer = {
  history: [],
  expanded: false,
  selectedConversationId: '',
  showDeleteButtons: false,
};

export const sidebarReducer = createReducer<ISidebarReducer>(
  initialState,
  on(
    SidebarActions.toggleExpanded,
    (state): ISidebarReducer => ({
      ...state,
      expanded: !state.expanded,
    })
  ),
  on(
    SidebarActions.close,
    (state): ISidebarReducer => ({
      ...state,
      expanded: false,
    })
  ),
  on(SidebarActions.requestHistorySuccess, (state, { history }) => ({
    ...state,
    history: history.map((i) => i).reverse(),
  })),
  on(
    SidebarActions.setShowDeleteButtons,
    (state, { showDeleteButtons }): ISidebarReducer => ({
      ...state,
      showDeleteButtons,
    })
  ),
  on(SidebarActions.deleteHistoryItemSuccess, (state, { conversationId }) => {
    const history = state.history.filter(
      (historyItem) => historyItem.id !== conversationId
    );

    return {
      ...state,
      showDeleteButtons: false,
      history,
    };
  }),
  on(
    SidebarActions.setSelectedConversationId,
    (state, { selectedConversationId }): ISidebarReducer => ({
      ...state,
      selectedConversationId,
    })
  ),
  on(SidebarActions.updateHistory, (state, { id, daysToNow, title }) => {
    const historyWithoutConversation = state.history.filter(
      (item) => item.id !== id
    );

    return {
      ...state,
      history: [
        {
          id,
          title,
          daysToNow,
        },
        ...historyWithoutConversation,
      ],
    };
  }),
  on(
    SidebarActions.deleteAllHistorySuccess,
    (state): ISidebarReducer => ({
      ...state,
      history: [],
    })
  )
);
