import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface ChatContext {
  id: string;
  context: string;
  connectorId: string;
}

export interface ChatContexts {
  applicationId: string;
  templates: ChatContext[];
}

@Injectable({
  providedIn: 'root',
})
export class ContextsService {
  constructor(private http: HttpClient) {}

  private url = `${environment.GATEWAY_URL}/api/orchestrator/connectors/contexts/options?applicationId=${environment.APPLICATION_ID}`;

  getContexts() {
    return this.http.get<ChatContexts>(this.url);
  }
}
