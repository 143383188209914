import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IChatReducer } from './chat.state';

export const chatKey = 'chat';

export const selectChat = createFeatureSelector<IChatReducer>(chatKey);

export const selectConversationId = createSelector(
  selectChat,
  (state) => state.conversationId
);

export const selectConnected = createSelector(
  selectChat,
  (state) => state.connected
);

export const selectMessages = createSelector(
  selectChat,
  (state) => state.messages
);

export const selectHasMessages = createSelector(
  selectChat,
  (state) => state.messages.length > 0
);

export const selectDisabledChatPrompt = createSelector(selectChat, (state) => {
  const notConnected = !state.connected;
  const isBotTypping = state.isBotTyping;
  const isLoadingMessage = state.isLoadingBotMessage;
  const isUploadingFile = state.isUploadingFile;

  return notConnected || isBotTypping || isLoadingMessage || isUploadingFile;
});

export const selectChatContext = createSelector(
  selectChat,
  (state) => state.chatContext
);

export const selectContexts = createSelector(
  selectChat,
  (state) => state.contexts
);

export const selectSuggestions = createSelector(
  selectChat,
  (state) => state.suggestions
);

export const selectIsLoadingBotMessage = createSelector(
  selectChat,
  (state) => state.isLoadingBotMessage
);
