import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IAuthReducer } from './auth.store';

export const authKey = 'auth';

export const selectAuth = createFeatureSelector<IAuthReducer>(authKey);

export const selectUserId = createSelector(
  selectAuth,
  (state) => state.user.id
);

export const selectAccessToken = createSelector(
  selectAuth,
  (state) => state.user.accessToken
);

export const selectUserName = createSelector(
  selectAuth,
  (state) => state.user.name
);

export const selectShouldShowUserAgreement = createSelector(
  selectAuth,
  (state) => !state.user.agreed && state.synced
);

export const selectUserPhoto = createSelector(
  selectAuth,
  (state) => state.user.photo
);

export const selectUserEmail = createSelector(
  selectAuth,
  (state) => state.user.email
);

export const selectUserSync = createSelector(
  selectAuth,
  (state) => state.synced
);
