import { differenceInCalendarDays, format } from 'date-fns';
import { IHistory } from 'src/app/core/api/chat-history.service';
import { PREV_14, PREV_30, PREV_7, TODAY, YESTERDAY } from '../constants';
import { IReducerHistory } from 'src/app/store/sidebar/sidebar.state';

export default function daysToNow(item: IHistory): IReducerHistory {
  const date = new Date(item.createdAt);
  const diff = differenceInCalendarDays(new Date(), date);

  let daysToNow = '';

  switch (true) {
    case diff == 0:
      daysToNow = TODAY;
      break;
    case diff == 1:
      daysToNow = YESTERDAY;
      break;
    case diff <= 7:
      daysToNow = PREV_7;
      break;
    case diff <= 14:
      daysToNow = PREV_14;
      break;
    case diff <= 30:
      daysToNow = PREV_30;
      break;
    default:
      daysToNow = format(new Date(item.createdAt), 'MMMM');
      break;
  }

  return {
    ...item,
    daysToNow,
  };
}
