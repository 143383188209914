<article class="container">
  <div class="header">
    <button (click)="close()">X</button>
    <h1>TERMS OF USE – Stellar/GPTap</h1>
  </div>
  <div class="body">
    <p>
      Welcome to Stellar (the “Tool”), AB InBev’s internal generative AI
      Assistant. The Tool is a private instance of ChatGPT created exclusively
      for AB InBev, with the purpose of assisting you in your work-related tasks
      and supporting on provide information/self-service related to ABI internal
      instructions and routines processes.
    </p>
    <p>
      You acknowledge and agree that you will use the Tool in accordance with
      these Terms of Service of ANHEUSER-BUSCH INBEV SA/NV, a company organized
      and existing under the laws of Belgium, whose registered office is at
      Grote Market 1, 1000 Brussels, Belgium, registered with the Register of
      Legal Entities under company number 417.497.106 and its
      <a
        href="https://anheuserbuschinbev.sharepoint.com/:x:/r/sites/Europe-DataPrivacy/Shared%20Documents/General/1.%20Internal/1.%20Power%20BI%20Reports/Entities.xlsx?d=w69032e6119b14943a8bf35993d862dc1&csf=1&web=1&e=2Z8xGD"
        >affiliates</a
      >
      (collectively, “we”, “us”, “AB InBev”, “company” or “ABI”).
    </p>
    <p>
      You acknowledge and agree that this Generative AI tool (Gen AI) is
      intended for use only within our company and your use of the Tool is
      subject to compliance with all applicable laws, regulations, company
      policies, and contractual restrictions. Ultimately, use of the Tool should
      be subject to adequate human oversight and judgement, for which you are
      responsible. Any collection and processing of personal data by this Tool,
      including employee data, is covered by the Stellar
      <a routerLink="/privacy-notice"> Privacy Notice </a> and will be done in
      accordance with these laws, regulations, policies, and contractual
      obligations for the protection of employee rights and privacy. You may
      consult our local policies (available in this link:
      <a
        href="https://anheuserbuschinbev.sharepoint.com/sites/ABIGlobalPolicies"
      >
        https://anheuserbuschinbev.sharepoint.com/sites/ABIGlobalPolicies
      </a>
      ) and seek additional information from local legal/E&C teams.
    </p>

    <ol>
      <li>
        <b>How the Tool functions </b>

        <p>
          The Tool is built within our own Microsoft Azure Cloud Infrastructure,
          and it enables generative AI capabilities from Open AI within our
          ecosystem. When you present a query to the system, it will search on
          (a) a static set of data trained with internal information to
          facilitate instructions related to ABI internal processes (as
          Knowledge articles and process documentation from areas such as
          People, IT and Procurement), (b) public Internet sources and a static
          set of data captured from the Internet as of the last training date of
          the Tool for answers that are most responsive to the query. Please
          note that, when the tool provides information from external sources,
          it will provide transparency and clear disclaimer on that.
        </p>
        <p>
          For more information about the personal data processed in the context
          of the Tool and your rights in this regard, please read the Stellar
          <a
            href="https://anheuserbuschinbev.sharepoint.com/:w:/s/DigitalEthics-GlobalTeam/EWNWE8K0R8pFiLpGXAKE834Bus1Kr3HbmFNlAfshLCoCZQ?e=4qZhyh"
            >Privacy Notice</a
          >.
        </p>
      </li>

      <li>
        <b>Permitted use of the Tool </b>

        <p>
          Your use of the Tool must always comply with the following principles:
        </p>

        <ul>
          <li>
            <b>Only use the Tool for your work at AB InBev:</b>Remember, Stellar
            was designed to support you with queries about People, IT, and
            Procurement processes, as well as other internal topics. Use the
            tool exclusively for tasks or responsibilities associated with your
            employment at or engagement by ABI; personal use is not permitted.
          </li>
          <li>
            <b>Do not share your Single Sign-On credentials:</b>
            You may not share your Single Sign-On credentials or otherwise
            enable any other person to use the Tool. All AB InBev employees and
            secondees who have access to the Tool should access the Tool only
            using his or her own Single Sign-On credentials.
          </li>
          <li>
            <b>Always follow the law and AB InBev’s Policies:</b>
            Always remember that, when using the Tool, you are in ABI’s
            environment. Hence, you are responsible for ensuring the lawful and
            ethical use of AI systems, upholding the
            <a
              href="https://www.ab-inbev.com/content/dam/universaltemplate/abinbev/pdf/AB%20InBev%20InterimCOBC%20Final%202020_New%20Principles.pdf"
              >Code of Business Conduct</a
            >
            and the principles of these Terms of Use and complying with all
            applicable laws, regulations, internal policies and contractual
            obligations. Seek further advice by contacting your Ethics &
            Compliance team directly or through the
            <a
              href="https://compliancechannelglobal.ab-inbev.com/authentication?returnUrl=%2F&queryParams=%7B%22%22:%22%22%7D"
            >
              Compliance Channel</a
            >.
          </li>
          <li>
            <b>Protect privacy and confidentiality:</b>The information you are
            in contact with in the context of your work for AB InBev might be
            confidential, commercially sensitive or include personal data. While
            this is an internally approved tool, please ensure that the
            individuals who have access to your queries and the generated
            outputs are limited to those with the appropriate authorizations or
            need to know. Hence, please refrain from inputting information that
            is not necessary for the execution of the task, including, without
            prior Legal approval, any personal data (e.g. data that can identify
            individuals), trade secrets or other non-public, proprietary or
            confidential information.
          </li>
          <li>
            <b
              >Don’t consider or hold out the output generated by Stellar as the
              position of AB InBev:</b
            >
            While Stellar is a private instance of a tool that is hosted on our
            Microsoft Azure Cloud infrastructure, it can generate outputs using
            information based on public Internet sources. When it occurs, you
            will be informed. Remember: those outputs generated using public
            Internet sources should not be considered as ABI position.
          </li>
          <li>
            <b
              >Don’t use the Tool to generate any output that will be
              distributed or made available outside of AB InBev:</b
            >
            Output generated through Stellar should only be used internally at
            ABI and not distributed or made available to third parties outside
            of the company. Additional review (e.g. conduct of IP clearance
            processes) may be required prior to any commercial or external use.
            Remember, Stellar should be used to consult internal processes
            regarding People/IT/Procurement topics.
          </li>
          <li>
            <b
              >Don’t simply trust Tool output is accurate or can be used. You
              are responsible for verifying the accuracy and appropriateness of
              Tool output before use:
            </b>
            Generative AI tools can respond in authoritative tones and generate
            output that seems accurate, but in fact, is false, misleading,
            biased, irrelevant or otherwise inappropriate. Generative AI, and
            ChatGPT specifically, has been known to “hallucinate”, that is,
            invent compelling and sometimes elaborate responses that are utterly
            false. Accordingly, you cannot trust that the output generated will
            be accurate, fair, relevant or otherwise appropriate for your
            intended use. As the user of the Tool, you are responsible for
            assessing and verifying the accuracy, fairness and suitability of
            all outputs. In addition, you cannot trust that the output generated
            can be lawfully used by AB InBev. For example, output generated
            using the Tool may infringe third-party intellectual property
            rights, and AI-generated software code may include third-party
            opensource technology that could present issues of non-compliance
            with the underlying open-source license, “copyleft contamination” or
            other material risks. For these reasons, do not use or rely on
            output without prior verification of both the relevance, accuracy,
            fairness and appropriateness of the output, and AB InBev’s right to
            use it or rely on it. Stellar serves to facilitate your daily
            routine and support you with main queries about specific topics.  If
            you have any questions or concerns about any output, please consult
            your Legal local counsel.
          </li>

          <li>
            <b
              >Don’t use the Tool to seek or provide legal, tax, regulatory,
              health, or medical advice:
            </b>
            As noted above, generative AI tools may generate output that is
            false, misleading, biased, irrelevant or otherwise inappropriate.
            You should never consider or provide Stellar’s output as legal, tax,
            regulatory, health, or medical advice. Any questions regarding these
            topics should be directed solely to your local legal, tax, corporate
            affairs, and ethics and compliance teams. Stellar is not focused on
            that and should be used for its main purpose mentioned above.
          </li>

          <li>
            <b>Be transparent:</b>
            Consider disclosing that you have used GPTap or Stellar wherever the
            circumstances may call for it, especially in two occasions: (1) if
            you use it as an advisor (such as to prepare a first draft of text),
            or (2) if you use it to substitute considerable portions of a human
            input (such as if an AI system prepares a whole internal company
            memorandum for you). You must be transparent about the fact that the
            work was prepared using an AI system, including how GPTap or Stellar
            were used in preparing such work and the extent to which AI outputs
            were subsequently verified or revised. Please consult your Ethics &
            Compliance or Legal local counsel. 
          </li>

          <li>
            <b>Ensure fairness, non-discrimination and safety:</b>
            Please do not assume that AI outputs are fair or without bias. GPTap
            provides information derived from public Internet sources, which may
            reflect societal or other biases (including lack of availability of
            data for underrepresented populations). You are responsible for
            ensuring that you use reliable and accurate data that is both
            sufficient and representative of the situations you aim to cover.
            Also, prior to use of the Tool’s outputs, you must verify that the
            outputs do not reflect, and the usage of such outputs would not
            result in, unfair exclusion or discrimination of people based on
            age, race, ethnicity, gender, nationality, sexual orientation,
            ability, political or religious belief and other
            characteristics. Please review ABI’s AI Playbook for additional
            guidelines regarding the ethical use of AI tools. 
          </li>
        </ul>
      </li>

      <li>
        <b>Contact & Grievance Mechanism  </b>
        <p>
          For any query about the Tool or its misuse you may contact us using
          our
          <a href="https://contactus.ab-inbev.com/en"
            >global “contact us” website</a
          >
          (for personal data concerns, please select the typology “Contact Data
          Protection Officer” in the Contact Us Form or review the Privacy
          Notice for more information) or your ABI contact person. 
        </p>
      </li>

      <li>
        <b>Liabilities </b>
        <p>
          The Tool must be used in accordance with your employment contract or
          third-party contract, including all liabilities applied therein. 
        </p>
      </li>

      <li>
        <b>Non compliance </b>
        <p>
          Your non-compliance with these Terms of Use (whether accidental, by
          intent or even abuse) may cause serious harm to your colleagues or to
          ABI. Any such non-compliance therefore gives ABI the right to block
          your access to the Tool (permanently or temporarily) and to take any
          appropriate additional measures to ensure compliance and/or to prevent
          further harm to your colleagues or ABI, without prejudice to the
          possible application of other disciplinary actions (if applicable).
        </p>
      </li>

      <li>
        <b>Updates  </b>
        <p>
          These Terms of Use may be updated from time to time, and you will be
          notified of such activity via our regular channels. Last updated June
          18th, 2024.
        </p>
      </li>
    </ol>
  </div>
  <div class="footer">
    <img src="assets/images/logo-expanded-transparent.png" alt="ABI Logo" />
  </div>
</article>
