import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IRagReducer } from './rag.state';

export const ragKey = 'rag';

export const selectRag = createFeatureSelector<IRagReducer>(ragKey);

export const selectSelectedLandingZone = createSelector(
  selectRag,
  (state) => state.selectedLandingZone
);

export const selectSelectedProduct = createSelector(
  selectRag,
  (state) => state.selectedProduct
);

export const selectSelectedConnector = createSelector(
  selectRag,
  (state) => state.selectedConnector
);

export const selectSelectedContext = createSelector(
  selectRag,
  (state) => state.selectedContext
);

export const selectLandingZones = createSelector(
  selectRag,
  (state) => state.landingZones
);

export const selectProducts = createSelector(
  selectRag,
  (state) => state.products
);

export const selectConnectors = createSelector(
  selectRag,
  (state) => state.connectors
);

export const selectContexts = createSelector(
  selectRag,
  (state) => state.contexts
);

export const selectDisableRefreshButton = createSelector(
  selectRag,
  (state) => !state.selectedConnector && !state.selectedContext
);

export const selectDisableNextButton = createSelector(
  selectRag,
  (state) => !state.selectedContext.id && !state.selectedContext.name
);

export const selectUploadTableData = createSelector(
  selectRag,
  (state) => state.uploadTableData
);

export const selectBenchmarkTableData = createSelector(
  selectRag,
  (state) => state.benchmarkTableData
);

export const selectPdfDocuments = createSelector(selectRag, (state) =>
  state.summary.pdfDocuments.map((x) => x.name)
);

export const selectBenchmarkDocuments = createSelector(selectRag, (state) =>
  state.summary.benchmarkDocuments.map((x) => x.file)
);

export const selectBenchmarkTableDataForSummary = createSelector(
  selectRag,
  (state) => {
    const uploadedBenchmarkFiles = state.summary.benchmarkDocuments.map(
      (item) => item.id
    );

    return state.benchmarkTableData.filter((item) =>
      uploadedBenchmarkFiles.includes(item.id)
    );
  }
);

export const selectTotalQuestions = createSelector(
  selectRag,
  (state) => state.summary.totalQuestions
);

export const selectTotalSynthQuestions = createSelector(
  selectRag,
  (state) => state.summary.totalSynthQuestions
);

export const selectTotalNonSynthQuestions = createSelector(
  selectRag,
  (state) => state.summary.totalNonSynthQuestions
);
