import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from './logging.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(private loggingService: LoggingService) {
    super();
  }

  override handleError(error: Error) {
    const allowedEnvironments = ['dev', 'uat', 'prod'];

    if (allowedEnvironments.includes(environment.mode)) {
      // this.loggingService.logException(error);
      console.log(error);
      return;
    }

    console.error(error);
  }
}
