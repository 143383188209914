export const TODAY = 'TODAY';
export const YESTERDAY = 'YESTERDAY';
export const PREV_7 = 'PREV_7';
export const PREV_14 = 'PREV_14';
export const PREV_30 = 'PREV_30';

const MONTHS = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];
