import { NgModule } from '@angular/core';
import { AgreeComponent } from './agree.component';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicButtonModule } from 'src/app/components/dynamic-button/dynamic-button.module';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'src/app/modal/modal.module';

const routes: Routes = [{ path: '', component: AgreeComponent }];

@NgModule({
  declarations: [AgreeComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CheckboxModule,
    DynamicButtonModule,
    FormsModule,
    ModalModule,
  ],
  exports: [AgreeComponent],
})
export class AgreeModule {}
