export const environment = {
  production: true,
  mode: 'prod',
  APP_REGISTRATION: {
    CLIENT_ID: 'f69bb773-2f59-4b48-a5dc-821a0d40e9a3',
  },
  BACKEND_URL: 'https://stellargpt.ab-inbev.com/saz/stellar',
  GATEWAY_URL: 'https://stellargpt.ab-inbev.com/saz/genai',
  APIM_SUBSCRIPTION_KEY: '',
  APPLICATION_ID: '2d4c1aa7-b38d-49d8-a1e1-61b3aa5f699c',
  APPLICATION_LABEL: 'Stellar',
  PERSONAL_RAG_CONNECTOR_ID: 'b40fbff3-f69a-4864-9442-f52ff4c85db7',
  APP_INSIGHTS: {
    INSTRUMENTATION_KEY: '',
    CONNECTION_STRING: '',
  },
  LAYOUT: {
    PERSONAL_RAG: false,
    CONTEXT_SELECTOR: false,
  },
};
