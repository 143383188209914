import { Injectable } from '@angular/core';
import {
  Actions,
  ROOT_EFFECTS_INIT,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { EMPTY, merge, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { ChatHistoryService } from 'src/app/core/api/chat-history.service';
import { SocketService, SuggestionType } from 'src/app/core/api/socket.service';

import { Router } from '@angular/router';
import { FeedbackService } from 'src/app/core/api/feedback.service';
import { AuthActions } from 'src/app/store/auth/auth.actions';
import {
  selectAccessToken,
  selectUserId,
} from 'src/app/store/auth/auth.selectors';
import { ChatActions } from './chat.actions';
import {
  selectChatContext,
  selectConversationId,
  selectMessages,
} from './chat.selectors';
import { ContextsService } from 'src/app/core/api/contexts.service';

@Injectable()
export class ChatEffects {
  constructor(
    private actions$: Actions,
    private socketService: SocketService,
    private router: Router,
    private store: Store,
    private chatHistoryService: ChatHistoryService,
    private feedbackService: FeedbackService,
    private contextsService: ContextsService
  ) {}

  connect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserDataSuccess),
      exhaustMap((action) => {
        return this.socketService
          .createConnection(action.accessToken)
          .start()
          .onConnected()
          .pipe(
            map(() => {
              return ChatActions.connected();
            }),
            catchError(() => EMPTY)
          );
      })
    );
  });

  getChatContexts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      concatMap(() => this.contextsService.getContexts()),
      map((res) => ChatActions.setContexts({ contexts: res.templates }))
    );
  });

  createOrConnectToRoom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ChatActions.getConversationByIdRequest,
        ChatActions.createRoomAndSendMessageRequest
      ),
      switchMap((action) => {
        return this.socketService
          .createRoom((action as any)?.conversationId)
          .onCreatedRoom()
          .pipe(
            map((conversationId) => {
              const conversationFromHistory =
                action.type === '[Chat Component] getConversationByIdRequest';

              const conversationFromHome = !this.router.url.includes('chat');

              if (conversationFromHome) {
                this.router.navigate(['chat']);
              }

              if (
                action.type ===
                '[Chat Component] createRoomAndSendMessageRequest'
              ) {
                return ChatActions.createRoomAndSendMessageSuccess({
                  conversationId,
                  message: action.message,
                });
              }

              if (conversationFromHistory) {
                this.router.navigate(['chat/', action.conversationId]);
              }

              return ChatActions.createdRoom({ conversationId });
            }),
            catchError(() => EMPTY)
          );
      })
    );
  });

  sendMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ChatActions.createRoomAndSendMessageSuccess,
        ChatActions.sendMessageRequest
      ),
      concatLatestFrom(() => [
        this.store.select(selectConversationId),
        this.store.select(selectChatContext),
      ]),
      exhaustMap(([action, conversationId, chatContext]) => {
        const selectedActionFlowOrEmpty =
          action.type === '[Chat Component] sendMessageRequest' &&
          action.actionFlow
            ? [action.actionFlow]
            : [];

        return of(
          this.socketService.sendMessage({
            conversationId,
            question: action.message,
            templateId: chatContext.id,
            connectorId: chatContext.connectorId,
            actionFlow: selectedActionFlowOrEmpty,
          })
        ).pipe(
          map(() => {
            return ChatActions.sendMessageSuccess({ message: action.message });
          }),
          catchError(() => EMPTY)
        );
      })
    );
  });

  getMessagesFromBot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ChatActions.sendMessageSuccess,
        ChatActions.stopTyping,
        ChatActions.getConversationByIdSuccess
      ),
      takeUntil(this.actions$.pipe(ofType(ChatActions.conversationCompleted))),
      concatLatestFrom(() => [
        this.store.select(selectMessages),
        this.store.select(selectAccessToken),
      ]),
      switchMap(([action, messages, accessToken]) => {
        if (action.type === '[Chat Component] stopTyping') {
          return of(ChatActions.stopTypingDone());
        }

        return merge(
          this.socketService.onConversationCompleted(),
          this.socketService.onConversationTyping()
        ).pipe(
          takeUntil(this.actions$.pipe(ofType(ChatActions.stopTypingDone))),
          map((res) => {
            if ('chunk' in res) {
              return ChatActions.messageReceived({
                chunk: res.chunk,
                messageId: res.messageId,
              });
            }

            const firstResponseFromBot = messages.length === 1;

            if (firstResponseFromBot) {
              this.router.navigate(['chat/', res.conversationId]);
            }

            const orderedSuggestions = (res.suggestions || []).sort((a, b) => {
              if (a.type === SuggestionType.Flow) {
                return -1;
              } else if (b.type === SuggestionType.Flow) {
                return 1;
              }

              return 0;
            });

            return ChatActions.conversationCompleted({
              conversationId: res.conversationId,
              fromInternet: res.context == 'INTERNET',
              message: res.answer,
              totalTokens: res.totalTokens || 0,
              prompt: res.question,
              accessToken,
              suggestions: orderedSuggestions,
              context: res.context,
              widgets: res.widgets,
              messageId: res.messageId,
            });
          }),
          catchError(() => EMPTY)
        );
      })
    );
  });

  getConversationById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.getConversationByIdRequest),
      mergeMap((action) =>
        this.chatHistoryService.getConversationById(action.conversationId)
      ),
      concatLatestFrom(() => [this.store.select(selectAccessToken)]),
      map(([conversation, accessToken]) => {
        return ChatActions.getConversationByIdSuccess({
          conversation,
          accessToken,
        });
      }),
      catchError(() => EMPTY)
    );
  });

  submitFeedback$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.submitFeedbackRequest),
      concatLatestFrom(() => [
        this.store.select(selectUserId),
        this.store.select(selectConversationId),
      ]),
      mergeMap(([action, userId, conversationId]) => {
        return this.feedbackService
          .submitFeedback({
            ...action.feedback,
            userId,
            conversationId,
          })
          .pipe(
            map(() => ChatActions.submitFeedbackSuccess()),
            catchError(() => EMPTY)
          );
      })
    );
  });
}
