import { Component, Inject } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  get authenticated(): boolean {
    return !!this.authService.instance.getActiveAccount();
  }

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,

    private authService: MsalService
  ) {}

  async logIn() {
    await this.authService.instance.loginRedirect(
      this.msalGuardConfig.authRequest as RedirectRequest
    );
  }
}
