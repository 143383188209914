import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface ISubmitFeedback {
  liked: boolean;
  rating?: string[];
  comment?: string;
  messageId: string;
  question: string;
  answer: string;
  userId: string;
  conversationId: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private http: HttpClient) {}

  private url = `${environment.BACKEND_URL}/api/feedbacks/`;

  submitFeedback({
    liked,
    rating,
    comment,
    messageId,
    userId,
    conversationId,
    question,
    answer,
  }: ISubmitFeedback) {
    if (liked) {
      return this.http.post(this.url, {
        liked,
        userId,
        conversationId,
        messageId,
        question,
        answer,
      });
    } else {
      return this.http.post(this.url, {
        liked,
        rating,
        comment,
        userId,
        conversationId,
        messageId,
        question,
        answer,
      });
    }
  }
}
