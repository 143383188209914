import { createReducer, on } from '@ngrx/store';
import { RagActions } from './rag.actions';
import { IRagReducer } from './rag.state';

const emptyLandingZone = {
  name: '',
};
const emptyProduct = {
  id: '',
  name: '',
  zone: '',
};
const emptyConnector = {
  applicationId: '',
  id: '',
  name: '',
};
const emptyContext = {
  name: '',
  id: '',
};

const initialState: IRagReducer = {
  landingZones: [],
  products: [],
  connectors: [],
  contexts: [],

  summary: {
    pdfDocuments: [],
    benchmarkDocuments: [],
    totalQuestions: 0,
    totalSynthQuestions: 0,
    totalNonSynthQuestions: 0,
  },

  selectedLandingZone: emptyLandingZone,
  selectedProduct: emptyProduct,
  selectedConnector: emptyConnector,
  selectedContext: emptyContext,

  uploadTableData: [
    {
      id: '-----',
      title: '-----',
      keywords: ['-----'],
      size: '-----',
      status: '-----',
      uploadDate: '-----',
      lastEdition: '-----',
      fileExtension: '-----',
    },
  ],

  benchmarkTableData: [
    {
      id: '-----',
      title: '-----',
      end_date: '-----',
      status: '-----',
      data: [],
    },
  ],
};

export const ragReducer = createReducer<IRagReducer>(
  initialState,
  on(
    RagActions.getLandingZonesSuccess,
    (state, { landing_zones }): IRagReducer => ({
      ...state,
      landingZones: landing_zones,
    })
  ),
  on(
    RagActions.getProductsSuccess,
    (state, { products }): IRagReducer => ({
      ...state,
      products,
    })
  ),
  on(
    RagActions.getConnectorsSuccess,
    (state, { connectors }): IRagReducer => ({
      ...state,
      connectors,
    })
  ),
  on(
    RagActions.getContextsSuccess,
    (state, { contexts }): IRagReducer => ({
      ...state,
      contexts,
    })
  ),
  on(
    RagActions.setSelectedLandingZone,
    (state, { landing_zone }): IRagReducer => ({
      ...state,
      selectedLandingZone: landing_zone,
      selectedProduct: emptyProduct,
      selectedConnector: emptyConnector,
      selectedContext: emptyContext,
    })
  ),
  on(
    RagActions.setSelectedProduct,
    (state, { product }): IRagReducer => ({
      ...state,
      selectedProduct: product,
      selectedConnector: emptyConnector,
      selectedContext: emptyContext,
    })
  ),
  on(
    RagActions.setSelectedConnector,
    (state, { connector }): IRagReducer => ({
      ...state,
      selectedConnector: connector,
      selectedContext: emptyContext,
    })
  ),
  on(
    RagActions.setSelectedContext,
    (state, { context }): IRagReducer => ({
      ...state,
      selectedContext: context,
    })
  ),
  on(
    RagActions.getUploadTableDataSuccess,
    (state, { uploadTableData }): IRagReducer => ({
      ...state,
      uploadTableData,
    })
  ),
  on(
    RagActions.removeUploadTableItem,
    (state, { id }): IRagReducer => ({
      ...state,
      uploadTableData: state.uploadTableData.filter((item) => item.id !== id),
    })
  ),
  on(
    RagActions.getBenchmarkTableDataSuccess,
    (state, { benchmarkTableData }): IRagReducer => ({
      ...state,
      benchmarkTableData,
    })
  ),
  on(
    RagActions.removeBenchmarkTableItem,
    (state, { id }): IRagReducer => ({
      ...state,
      benchmarkTableData: state.benchmarkTableData.filter(
        (item) => item.id !== id
      ),
    })
  ),
  on(
    RagActions.setPdfDocuments,
    (state, { pdfDocuments }): IRagReducer => ({
      ...state,
      summary: {
        ...state.summary,
        pdfDocuments: state.summary.pdfDocuments.concat(pdfDocuments),
      },
    })
  ),
  on(
    RagActions.setBenchmarkDocuments,
    (state, { benchmarkDocuments }): IRagReducer => ({
      ...state,
      summary: {
        ...state.summary,
        benchmarkDocuments:
          state.summary.benchmarkDocuments.concat(benchmarkDocuments),
      },
    })
  ),
  on(
    RagActions.setTotalQuestions,
    (state, { totalQuestions, totalSynthQuestions }): IRagReducer => ({
      ...state,
      summary: {
        ...state.summary,
        totalQuestions: totalQuestions,
        totalSynthQuestions: totalSynthQuestions,
        totalNonSynthQuestions: totalQuestions - totalSynthQuestions,
      },
    })
  ),
  on(
    RagActions.reset,
    (): IRagReducer => ({
      ...initialState,
    })
  )
);
