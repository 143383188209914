<div class="container">
  <div class="sidebar" [ngClass]="{ expanded: isOpen$ | async }">
    <nav>
      <div class="toggle-controls">
        <button
          class="btn new-chat"
          (click)="handleNewChat()"
          [ngClass]="{ large: isOpen$ | async }"
        >
          <i class="material-icons">add_circle_outline</i>

          @if (isOpen$ | async) {
            <span>{{ 'COMPONENTS.SIDEBAR.HISTORY.NEW_CHAT' | translate }}</span>
          }
        </button>
        <button
          class="btn hide-mobile toggle-button hidden"
          (click)="toggleExpanded()"
        >
          @if (isOpen$ | async) {
            <img
              src="assets/images/sidebar-arrow-left.svg"
              alt=""
              height="24"
              width="24"
            />
          } @else {
            <img
              id="sidebar-arrow-right"
              src="assets/images/sidebar-arrow-left.svg"
              alt=""
              width="24"
              height="24"
            />
          }
        </button>
      </div>

      @if (isOpen$ | async) {
        <div class="scroll">
          <div class="history-title">
            {{ 'COMPONENTS.SIDEBAR.HISTORY.HISTORY' | translate }}
          </div>
          <div class="relative">
            @for (message of history$ | async; track message.id) {
              <li class="list-style-none flex-list">
                <ul
                  class="history-item"
                  [ngClass]="{
                    selected: message.id === (selectedConversationId$ | async),
                  }"
                >
                  <a [routerLink]="['/chat/', message.id]">
                    @if (
                      (showDeleteButtons$ | async) === false ||
                      message.id !== (selectedConversationId$ | async)
                    ) {
                      <mat-icon> chat_bubble_outline</mat-icon>
                    }

                    @if (
                      (showDeleteButtons$ | async) === true &&
                      message.id === (selectedConversationId$ | async)
                    ) {
                      <mat-icon> delete_outline</mat-icon>
                    }

                    <div class="item-title">
                      @if (
                        (showDeleteButtons$ | async) &&
                        message.id === (selectedConversationId$ | async)
                      ) {
                        {{
                          'COMPONENTS.SIDEBAR.HISTORY.DELETE'
                            | translate: { value: message.title }
                        }}
                      } @else {
                        {{ message.title }}
                      }
                    </div>
                  </a>

                  @if (
                    (showDeleteButtons$ | async) === false &&
                    message.id === (selectedConversationId$ | async)
                  ) {
                    <div class="crud-btns-container">
                      <button class="crud-btns" (click)="openModal(message.id)">
                        <mat-icon fontIcon="delete_outline" />
                      </button>
                    </div>
                  }

                  <modal [id]="message.id">
                    <delete-item
                      [messageTitle]="message.title"
                      [id]="(selectedConversationId$ | async) || ''"
                    />
                  </modal>
                </ul>
              </li>
            }
          </div>
        </div>
      }

      <div class="download">
        @if (isOpen$ | async) {
          <dynamic-button (click)="downloadChatHistory()">
            {{ 'COMPONENTS.SIDEBAR.HISTORY.DOWNLOAD' | translate }}
          </dynamic-button>
        }
      </div>
    </nav>

    <modal id="confirmModal">
      <delete-history />
    </modal>
  </div>
</div>
