import { NgModule } from '@angular/core';
import { DynamicButtonComponent } from './dynamic-button.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [DynamicButtonComponent],
  exports: [DynamicButtonComponent],
  imports: [CommonModule],
})
export class DynamicButtonModule {}
