import { Component } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'privacy-modal',
  templateUrl: './privacy-notice.component.html',
  styleUrls: ['./privacy-notice.component.scss'],
})
export class PrivacyModalComponent {
  constructor(private modalService: ModalService) {}

  close() {
    this.modalService.close();
  }
}
