import { Component, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { Store } from '@ngrx/store';
import { SidebarActions } from 'src/app/store/sidebar/sidebar.actions';

@Component({
  selector: 'delete-item',
  templateUrl: './delete-item-confirm.component.html',
  styleUrls: ['./delete-item-confirm.component.scss'],
})
export class DeleteItemComponent {
  constructor(
    protected modalService: ModalService,
    private store: Store
  ) {}

  @Input() messageTitle = '';
  @Input() id = '';

  step = 1;

  onConfirm(id: string) {
    this.id = id;
    this.step = 2;
  }

  onCloseModalAfterDelete() {
    this.modalService.close();

    this.store.dispatch(
      SidebarActions.deleteHistoryItemRequest({ conversationId: this.id })
    );

    this.step = 1;
  }
}
