import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, combineLatest, exhaustMap, map } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectSelectedConnector,
  selectSelectedContext,
  selectSelectedProduct,
} from 'src/app/store/rag/rag.selectors';

interface Contexts {
  id: string;
  connectorId: string;
  contexts: { id: string; context: string }[];
}

export interface FormatedContexts {
  name: string;
  id: string;
}

export interface FormatedProduct {
  id: string;
  name: string;
}

export interface Connector {
  applicationId: string;
  id: string;
  name: string;
}

export interface RagDocument {
  name: string;
}

export interface UploadedBenchmarkDocuments {
  id: string;
  file: string;
}

export interface PDFDocument {
  id: string;
  status: Status;
  title: string;
  size: number;
  upload_date: string;
  last_edition: string;
  keywords: string[];
  context: string;
  file_extension: string;
}
interface BenchmarkDocument {
  contextId: string;
  data: GroundTruth[];
  end_date: string;
  title: string;
  id: string;
  scores: Scores;
  start_date: string;
  status: Status;
}
interface LandingZone {
  id: string;
  applicationId: string;
  zone: string;
}

export interface Product {
  id: string;
  name: string;
  zone: string;
}

export interface FormatedLandingZone {
  name: string;
}

export interface Question {
  id: string;
  text: string;
  answer: string | null;
  templateId: string;
  isSynthetic: boolean | DefaultTableValue;
}

interface NewQuestion {
  question: string;
  answer: string;
}

export interface EditQuestion extends NewQuestion {
  id: string;
}

interface CreatedQuestionResponse {
  id: string;
  learnId: null;
  text: string;
  answer: string;
  templateId: string;
}
@Injectable({
  providedIn: 'root',
})
export class RagService {
  constructor(
    private http: HttpClient,
    private store: Store
  ) {}

  selectedProduct$ = this.store.select(selectSelectedProduct);
  selectedConnector$ = this.store.select(selectSelectedConnector);
  selectedContext$ = this.store.select(selectSelectedContext);

  getUploadPDF_URL() {
    return combineLatest([this.selectedConnector$]).pipe(
      map(([connector]) => {
        return `${environment.GATEWAY_URL}/connectors/${connector.id}/knowledge/documents`;
      })
    );
  }

  getUploadCSV_URL() {
    return combineLatest([this.selectedConnector$, this.selectedContext$]).pipe(
      map(([connector, context]) => {
        return `${environment.GATEWAY_URL}/connectors/${connector.id}/groundtruth/${context.name}/benchmark_document`;
      })
    );
  }

  getLandingZones(): Observable<FormatedLandingZone[]> {
    const landing_zones_endpoint = `${environment.GATEWAY_URL}/api/identity/applications/zones`;

    return this.http.get<LandingZone[]>(landing_zones_endpoint).pipe(
      map((landing_zones) => {
        const initialValue: FormatedLandingZone[] = [];
        const distinct_landing_zones = landing_zones.reduce(
          (accumulator, currentValue) => {
            const alreadyAddedZoneToAcc = accumulator.find(
              ({ name }) => name === currentValue.zone
            );

            if (alreadyAddedZoneToAcc) {
              return accumulator;
            }

            accumulator.push({ name: currentValue.zone });

            return accumulator;
          },
          initialValue
        );

        return distinct_landing_zones;
      })
    );
  }

  getProductsByLandingZone(landing_zone: string): Observable<Product[]> {
    const product_endpoint = `${environment.GATEWAY_URL}/api/identity/applications/options?zone=${landing_zone}`;

    return this.http.get<Product[]>(product_endpoint);
  }

  getConnectorsByProduct(product: string) {
    const CONNECTOR_TYPE_QUERY = 1;

    const connector_endpoints = `${environment.GATEWAY_URL}/api/orchestrator/connectors/options?type=${CONNECTOR_TYPE_QUERY}&applicationId=${product}`;

    return this.http.get<Connector[]>(connector_endpoints);
  }

  getContextsByConnector(connector: string): Observable<FormatedContexts[]> {
    const context_endpoint = `${environment.GATEWAY_URL}/api/orchestrator/connectors/${connector}/contexts`;

    return this.http.get<Contexts>(context_endpoint).pipe(
      map((res) => {
        return res.contexts.map((context) => ({
          name: context.context,
          id: context.id,
        }));
      })
    );
  }

  getDocuments(): Observable<UploadTableItem[]> {
    return combineLatest([this.selectedConnector$, this.selectedContext$]).pipe(
      exhaustMap(([connector, context]) => {
        const documents_endpoint = `${environment.GATEWAY_URL}/connectors/${connector.id}/knowledge/documents-metadata?context=${context.name}&sort_key=upload_date&ordering=desc`;

        return this.http.get<PDFDocument[]>(documents_endpoint);
      }),
      map((documents) => {
        const formatedDocuments = documents.map((doc) => {
          return {
            id: doc.id,
            title: doc.title,
            keywords: doc.keywords,
            status: doc.status,
            lastEdition: new Date(doc.last_edition),
            uploadDate: new Date(doc.upload_date),
            size: `${doc.size}KB`,
            fileExtension: doc.file_extension,
          };
        });

        return formatedDocuments;
      })
    );
  }

  getQuestions(): Observable<Question[]> {
    return this.selectedContext$.pipe(
      exhaustMap((context) => {
        const verifier_endpoint = `${environment.GATEWAY_URL}/api/orchestrator/questions?templateId=${context.id}`;

        return this.http.get<Question[]>(verifier_endpoint);
      })
    );
  }

  addQuestion(payload: NewQuestion): Observable<Question> {
    return combineLatest([
      this.selectedProduct$,
      this.selectedConnector$,
      this.selectedContext$,
    ]).pipe(
      exhaustMap(([product, connector, context]) => {
        const verifier_endpoint = `${environment.GATEWAY_URL}/api/orchestrator/questions`;

        return this.http.post<CreatedQuestionResponse>(verifier_endpoint, {
          applicationId: product.id,
          connectorId: connector.id,
          templateId: context.id,
          learnId: null,

          text: payload.question,
          answer: payload.answer,
        });
      }),
      map((res) => {
        return {
          id: res.id,
          text: res.text,
          answer: res.answer,
          templateId: res.templateId,
          isSynthetic: false,
        };
      })
    );
  }

  updateQuestion(payload: EditQuestion) {
    return combineLatest([
      this.selectedProduct$,
      this.selectedConnector$,
      this.selectedContext$,
    ]).pipe(
      exhaustMap(([product, connector, context]) => {
        const verifier_endpoint = `${environment.GATEWAY_URL}/api/orchestrator/questions/${payload.id}`;

        return this.http.put(verifier_endpoint, {
          applicationId: product.id,
          connectorId: connector.id,
          templateId: context.id,
          learnId: null,

          text: payload.question,
          answer: payload.answer,
        });
      })
    );
  }

  deleteQuestionById(id: string) {
    const delete_question_endpoint = `${environment.GATEWAY_URL}/api/orchestrator/questions/${id}`;

    return this.http.delete(delete_question_endpoint);
  }

  getGroundTruthDocuments(): Observable<BenchmarkTableItem[]> {
    return combineLatest([this.selectedConnector$, this.selectedContext$]).pipe(
      exhaustMap(([connector, context]) => {
        const endpoint = `${environment.GATEWAY_URL}/connectors/${connector.id}/groundtruth/${context.name}/benchmark_documents?sort_key=upload_date&ordering=desc`;

        return this.http.get<BenchmarkDocument[]>(endpoint);
      }),
      map((documents) => {
        const formatedDocuments = documents.map((doc) => ({
          id: doc.id,
          end_date: new Date(doc.end_date),
          scores: doc.scores,
          status: doc.status,
          title: doc.title,
          data: doc.data,
        }));

        return formatedDocuments;
      })
    );
  }

  downloadPDFDocument(document_id: string) {
    return combineLatest([this.selectedConnector$, this.selectedContext$]).pipe(
      exhaustMap(([connector, context]) => {
        const download_endpoint = `${environment.GATEWAY_URL}/connectors/${connector.id}/knowledge/${context.name}/${document_id}/download`;

        return this.http.get(download_endpoint, {
          headers: {
            Accept: '*/*',
          },
          responseType: 'arraybuffer',
        });
      })
    );
  }

  downloadCSVDocument(benchmark_id: string) {
    return combineLatest([this.selectedConnector$]).pipe(
      exhaustMap(([connector]) => {
        const download_endpoint = `${environment.GATEWAY_URL}/connectors/${connector.id}/groundtruth/${benchmark_id}/benchmark_document/original_download`;

        return this.http.get(download_endpoint, {
          headers: {},
          responseType: 'arraybuffer',
        });
      })
    );
  }

  downloadGroundTruthDocument(benchmark_id: string) {
    return combineLatest([this.selectedConnector$]).pipe(
      exhaustMap(([connector]) => {
        const download_endpoint = `${environment.GATEWAY_URL}/connectors/${connector.id}/groundtruth/${benchmark_id}/benchmark_document/download`;

        return this.http.get(download_endpoint, {
          headers: {},
          responseType: 'arraybuffer',
        });
      })
    );
  }

  getGroundTruth() {
    const benchmark_id = '';

    return combineLatest([this.selectedConnector$]).pipe(
      exhaustMap(([connector]) => {
        const ground_truth_endpoint = `${environment.GATEWAY_URL}/connectors/${connector.id}/groundtruth/${benchmark_id}/questions`;

        return this.http.get(ground_truth_endpoint);
      })
    );
  }

  deletePDFDocumentById(id: string) {
    return combineLatest([this.selectedConnector$, this.selectedContext$]).pipe(
      exhaustMap(([connector, context]) => {
        const delete_pdf_endpoint = `${environment.GATEWAY_URL}/connectors/${connector.id}/knowledge/${context.name}`;

        return this.http.delete(delete_pdf_endpoint, {
          body: [id],
        });
      })
    );
  }

  deleteCSVDocumentById(id: string) {
    return combineLatest([this.selectedConnector$, this.selectedContext$]).pipe(
      exhaustMap(([connector, context]) => {
        const delete_pdf_endpoint = `${environment.GATEWAY_URL}/connectors/${connector.id}/groundtruth/${id}?context=${context.name}`;

        return this.http.delete(delete_pdf_endpoint);
      })
    );
  }
}
