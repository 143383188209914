import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { ChatActions } from 'src/app/store/chat/chat.actions';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  hasHistory$: Observable<boolean>;
  isOpen = false;
  urlIncludesChat = this.location.path().includes('/chat');

  constructor(
    private navigation: NavigationService,
    private store: Store,
    private location: Location
  ) {
    this.hasHistory$ = this.navigation.hasHistory$;

    location.onUrlChange((url) => {
      this.urlIncludesChat = url.includes('/chat');
    });
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  goBack() {
    this.navigation.back();
  }

  resetChat() {
    this.store.dispatch(ChatActions.reset());
  }
}
