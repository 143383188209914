<div class="confirmation-container">
  <div class="confirmation-header">
    <p>
      {{
        step === 1
          ? ('MODAL.VARIATIONS.CONFIRMATION_ITEM.TITLE' | translate)
          : ('MODAL.VARIATIONS.CONFIRMATION_ITEM.TITLE_CONFIRM' | translate)
      }}
    </p>
  </div>

  <div class="text-container">
    <p>
      {{
        step === 1
          ? ('MODAL.VARIATIONS.CONFIRMATION_ITEM.TEXT'
            | translate: { value: messageTitle })
          : ('MODAL.VARIATIONS.CONFIRMATION_ITEM.TEXT_CONFIRM'
            | translate: { value: messageTitle })
      }}
    </p>
  </div>

  <div class="button-container">
    @if (step === 1) {
      <button class="confirm-button" (click)="onConfirm(id)">
        {{ 'MODAL.VARIATIONS.CONFIRMATION.CONFIRM' | translate }}
      </button>
    }

    <button
      class="cancel-button"
      (click)="step === 1 ? modalService.close() : onCloseModalAfterDelete()"
    >
      {{
        step === 1
          ? ('MODAL.VARIATIONS.CONFIRMATION.CANCEL' | translate)
          : ('MODAL.VARIATIONS.CONFIRMATION.CLOSE' | translate)
      }}
    </button>
  </div>
</div>
