import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IReducerHistory } from './sidebar.state';

export const SidebarActions = createActionGroup({
  source: 'Sidebar Component',
  events: {
    toggleExpanded: emptyProps(),
    requestHistory: emptyProps(),
    close: emptyProps(),
    requestHistorySuccess: props<{ history: IReducerHistory[] }>(),
    setShowDeleteButtons: props<{ showDeleteButtons: boolean }>(),
    setSelectedConversationId: props<{
      selectedConversationId: string;
    }>(),
    deleteHistoryItemRequest: props<{ conversationId: string }>(),
    deleteHistoryItemSuccess: props<{ conversationId: string }>(),
    updateHistory: props<{
      id: string;
      title: string;
      daysToNow: string;
    }>(),
    deleteAllHistoryRequest: emptyProps(),
    deleteAllHistorySuccess: emptyProps(),
  },
});
