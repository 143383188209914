import { NgModule } from '@angular/core';

import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { MsalGuard, MsalInterceptor, MsalModule } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { CONSTANTS } from './constants';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

@NgModule({
  imports: [
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          authority: CONSTANTS.AUTHORITY_URL,
          clientId: environment.APP_REGISTRATION.CLIENT_ID,
          redirectUri: '/',
          knownAuthorities: [
            'knownAuthorities.b2clogin.com',
            'knownAuthorities.onmicrosoft.com',
          ],
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: isIE,
        },
        system: {
          loggerOptions: {
            loggerCallback,
            logLevel: LogLevel.Info,
            piiLoggingEnabled: false,
          },
          allowNativeBroker: false,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: CONSTANTS.AUTH_SCOPES,
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [environment.BACKEND_URL, CONSTANTS.AUTH_SCOPES],
          [CONSTANTS.GRAPH_V1_URL, CONSTANTS.GRAPH_SCOPES],
          [environment.GATEWAY_URL, CONSTANTS.AUTH_SCOPES],
        ]),
      }
    ),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard,
  ],
})
export class AuthModule {}
