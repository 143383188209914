<div class="language-select-container">
  <label for="language-select">{{
    'COMPONENTS.HEADER.LANGUAGE_SELECT.LABEL' | translate
  }}</label>

  <ng-select
    [clearable]="false"
    [(ngModel)]="selectedLanguage"
    (change)="onChange($event)"
    class="custom-select"
    labelForId="language-select"
  >
    @for (option of options; track option.label) {
      <ng-option [value]="option.label">
        <img class="flag" [src]="option.image" alt="" />
        {{ option.label | uppercase }}
      </ng-option>
    }
  </ng-select>
</div>
