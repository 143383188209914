import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { exhaustMap, map, switchMap } from 'rxjs/operators';

import { RagActions } from './rag.actions';
import { RagService } from 'src/app/core/api/rag.service';

@Injectable()
export class RagEffects {
  constructor(
    private actions$: Actions,
    private ragService: RagService
  ) {}

  getLandingZones$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RagActions.getLandingZonesRequest),
      exhaustMap(() =>
        from(this.ragService.getLandingZones()).pipe(
          map((landing_zones) => {
            return RagActions.getLandingZonesSuccess({
              landing_zones,
            });
          })
        )
      )
    );
  });

  getProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RagActions.setSelectedLandingZone),
      exhaustMap((action) =>
        from(
          this.ragService.getProductsByLandingZone(action.landing_zone.name)
        ).pipe(
          map((products) => {
            return RagActions.getProductsSuccess({ products });
          })
        )
      )
    );
  });

  getConnectors$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RagActions.setSelectedProduct),
      exhaustMap((action) =>
        from(this.ragService.getConnectorsByProduct(action.product.id)).pipe(
          map((connectors) => {
            return RagActions.getConnectorsSuccess({ connectors });
          })
        )
      )
    );
  });

  getContexts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RagActions.setSelectedConnector),
      exhaustMap((action) =>
        from(this.ragService.getContextsByConnector(action.connector.id)).pipe(
          map((contexts) => {
            return RagActions.getContextsSuccess({ contexts });
          })
        )
      )
    );
  });

  getUploadTableData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RagActions.getUploadTableDataRequest),
      switchMap(() =>
        from(this.ragService.getDocuments()).pipe(
          map((uploadTableData) => {
            return RagActions.getUploadTableDataSuccess({ uploadTableData });
          })
        )
      )
    );
  });

  getBenchmarkTableData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RagActions.getBenchmarkTableDataRequest),
      switchMap(() =>
        from(this.ragService.getGroundTruthDocuments()).pipe(
          map((benchmarkTableData) => {
            return RagActions.getBenchmarkTableDataSuccess({
              benchmarkTableData,
            });
          })
        )
      )
    );
  });
}
