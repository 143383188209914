import { Component, Input } from '@angular/core';

type Variants = 'default' | 'transparent' | 'green' | 'chat' | 'agree';

@Component({
  selector: 'dynamic-button',
  templateUrl: './dynamic-button.component.html',
  styleUrls: ['./dynamic-button.component.scss'],
})
export class DynamicButtonComponent {
  @Input() disabled = false;
  @Input() type = 'button';
  @Input() variant: Variants = 'default';
}
