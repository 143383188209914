import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserPreferencesService } from 'src/app/core/services/user-preferences.service';

type Language = 'en' | 'pt' | 'es';

interface ILanguageOption {
  label: Language;
  image: string;
}

@Component({
  selector: 'language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LanguageSelectComponent {
  selectedLanguage = 'en';

  constructor(
    private translateService: TranslateService,
    private userPreferences: UserPreferencesService
  ) {
    this.getLanguageFromUserPreferences();
  }

  options: ILanguageOption[] = [
    { image: 'assets/images/us-flag.svg', label: 'en' },
    { image: 'assets/images/br-flag.svg', label: 'pt' },
    { image: 'assets/images/es-flag.svg', label: 'es' },
  ];

  onChange(languageSelected: Language) {
    document.documentElement.setAttribute('lang', languageSelected);
    this.translateService.use(languageSelected);
    this.userPreferences.set('language', languageSelected);
  }

  private getLanguageFromUserPreferences() {
    const preferences = this.userPreferences.getAll();

    if (preferences) {
      document.documentElement.setAttribute('lang', preferences.language);
      this.selectedLanguage = preferences.language;
      this.translateService.use(preferences.language);
    }
  }
}
