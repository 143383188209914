import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { UserComponent } from './user/user.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MaterialModule } from 'src/app/core/material/material.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';

@NgModule({
  declarations: [
    HeaderComponent,
    UserComponent,
    LanguageSelectComponent,
    BreadcrumbComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    StoreModule,
    EffectsModule,
    TranslateModule,
    NgSelectModule,
    FormsModule,
    BreadcrumbModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
