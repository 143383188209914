<header class="stellar-header noselect">
  <div class="left-side">
    <app-breadcrumb />
  </div>
  <div class="right-side">
    <!-- <i class="material-icons">lightbulb</i> -->
    <language-select />
    <user />
  </div>
</header>
