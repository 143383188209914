import { Injectable } from '@angular/core';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ChatActions } from 'src/app/store/chat/chat.actions';
import { environment } from 'src/environments/environment';

export interface ISuggestion {
  id: string;
  type: 1 | 2;
  value: string;
}

export enum SuggestionType {
  Flow = 1,
  Prompt = 2,
}

export interface Widget {
  id: string;
  type: number;
}

interface IConversationStarted {
  conversationId: string;
  question: string;
  connectorId: string;
  templateId: string;
}

interface IConversationTyping {
  chunk: string;
  conversationId: string;
  messageId: string;
}

interface IConversationCompleted {
  id: string;
  conversationId: string;
  answer: string;
  context?: string;
  messageId: string;
  connectorId?: string;
  question: string;
  userId: string;
  promptTokens?: number;
  template?: string;
  totalCost?: number;
  totalTokens?: number;
  suggestions: ISuggestion[];
  widgets?: Widget[];
}

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  constructor(
    private store: Store,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  private hubConnection!: HubConnection;

  private onConnected$!: Observable<string>;
  private onCreatedRoom$!: Observable<string>;
  private onConversationStarted$!: Observable<IConversationStarted>;
  private onConversationTyping$!: Observable<IConversationTyping>;
  private onConversationCompleted$!: Observable<IConversationCompleted>;

  createConnection(accessToken: string) {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.BACKEND_URL}/chat`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => accessToken,
      })
      .build();

    return this;
  }

  start() {
    this.hubConnection.start().catch((e) => {
      console.log('Socket error:', e);
    });

    this.initializeListeners();

    this.keepTabActive();

    return this;
  }

  onConnected(): Observable<string> {
    return this.onConnected$;
  }

  createRoom(conversationId?: string) {
    this.hubConnection.send(
      'createRoom',
      conversationId,
      environment.APPLICATION_ID,
      environment.APPLICATION_LABEL
    );

    return this;
  }

  onCreatedRoom(): Observable<string> {
    return this.onCreatedRoom$;
  }

  onConversationStarted(): Observable<IConversationStarted> {
    return this.onConversationStarted$;
  }

  onConversationTyping(): Observable<IConversationTyping> {
    return this.onConversationTyping$;
  }

  onConversationCompleted(): Observable<IConversationCompleted> {
    return this.onConversationCompleted$;
  }

  sendMessage(message: {
    conversationId: string;
    question: string;
    templateId: string;
    connectorId: string;
    actionFlow: ISuggestion[];
  }) {
    this.store.dispatch(ChatActions.resetSuggestions());

    const actionFlowIdsOrNull = message.actionFlow.length
      ? message.actionFlow.map((flow) => flow.id)
      : null;

    return this.hubConnection.send('conversationStarted', {
      conversationId: message.conversationId,
      question: message.question,
      connectorId: message.connectorId,
      templateId: message.templateId,
      flowIds: actionFlowIdsOrNull,
      applicationId: environment.APPLICATION_ID,
      applicationLabel: environment.APPLICATION_LABEL,
    });
  }

  private initializeListeners() {
    this.onConnected$ = new Observable((observer) => {
      this.hubConnection.on('onConnect', () => observer.next());
    });

    this.onCreatedRoom$ = new Observable((observer) => {
      this.hubConnection.on('onCreateRoom', (roomId: string) => {
        observer.next(roomId);
        observer.complete();
      });
    });

    this.onConversationStarted$ = new Observable((observer) => {
      this.hubConnection.on('onConversationStarted', (msg) => {
        observer.next(msg);
      });
    });

    this.onConversationTyping$ = new Observable((observer) => {
      this.hubConnection.on('onConversationTyping', (msg) => {
        observer.next(msg);
      });
    });

    this.onConversationCompleted$ = new Observable((observer) => {
      this.hubConnection.on('onConversationCompleted', (msg) => {
        observer.next(msg);
      });
    });

    this.onError();
  }

  private onError() {
    this.hubConnection.on('onError', () => {
      this.showErrorToastr();
      this.store.dispatch(ChatActions.conversationWithError());
    });
  }

  private showErrorToastr() {
    this.translate
      .get('CORE.API.SOCKET.TOASTR')
      .subscribe(
        (toastr: { ERROR_TITLE: string; ERROR_DESCRIPTION: string }) => {
          this.toastr.error(toastr.ERROR_TITLE, toastr.ERROR_DESCRIPTION, {
            progressBar: true,
            closeButton: true,
          });
        }
      );
  }

  private keepTabActive() {
    // This blocks the browser from making the user tab inactive, thus affecting the websocket connection
    // For info, see: https://learn.microsoft.com/pt-br/aspnet/core/signalr/javascript-client?view=aspnetcore-8.0&tabs=visual-studio#bsleep
    let lockResolver;

    if (navigator && navigator.locks && navigator.locks.request) {
      const promise = new Promise((res) => {
        lockResolver = res;
      });

      const unique_lock_name = new Date().toString();

      navigator.locks.request(unique_lock_name, { mode: 'shared' }, () => {
        return promise;
      });
    }
  }
}
