import { NgModule, isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule as StoreNgrx } from '@ngrx/store';

import { chatReducer } from './chat/chat.reducer';
import { chatKey } from './chat/chat.selectors';
import { sidebarReducer } from './sidebar/sidebar.reducer';
import { sidebarKey } from './sidebar/sidebar.selectors';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { ChatEffects } from './chat/chat.effects';
import { SidebarEffects } from './sidebar/sidebar.effects';
import { AuthEffects } from './auth/auth.effects';
import { authReducer } from './auth/auth.reducer';
import { authKey } from './auth/auth.selectors';
import { ragKey } from './rag/rag.selectors';
import { ragReducer } from './rag/rag.reducer';
import { RagEffects } from './rag/rag.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreNgrx.forRoot({
      [chatKey]: chatReducer,
      [authKey]: authReducer,
      [sidebarKey]: sidebarReducer,
      [ragKey]: ragReducer,
    }),
    EffectsModule.forRoot(ChatEffects, AuthEffects, SidebarEffects, RagEffects),
    StoreDevtoolsModule.instrument({
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75,
      connectInZone: true,
    }),
  ],
})
export class StoreModule {}
